.rules {

  &__wrapper {
    width: 280px;
  }

  &__attached {
    max-width: 700px;
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    padding: 5px 10px;
    white-space: nowrap;

    .pi {
      padding-left: 3px;
      position: relative;
      top: 1px;
      cursor: pointer;

      &:hover {
        color: #eb5757;
      }
    }
  }
}