@import "colors";

.loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #ccc;
  z-index: 5;
  opacity: 0.5;
  pointer-events: none;

  .p-progress-spinner-circle {
    stroke: $color-gray !important;
  }
}

.login, .clients {
  .header {
    .separator-middle {
      background-color: $color-blue;
    }
  }
}

.administrators {
  .header {
    .separator-middle {
      background-color: $color-green-light;
    }
  }
}

.references, .company-groups, .brands, .locations, .branch-types {
  .header {
    .separator-middle {
      background-color: $color-red;
    }
  }
}

.companies {
  .header {
    .separator-middle {
      background-color: $color-blue-light2;
    }
  }
}


.branches {
  .header {
    .separator-middle {
      background-color: $color-lilac;
    }
  }
}


.integrations {
  .header {
    .separator-middle {
      background-color: $color-orange;
    }
  }
}

.users {
  .header {
    .separator-middle {
      background-color: $color-blue-light;
    }
  }
}

.analytics {
  .header {
    .separator-middle {
      background-color: $color-green-main;
    }
  }
}
