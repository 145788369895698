@import 'styles/main';

.clients {
  .header-container {
    h1 {
      span {
        color: $color-blue;
      }
    }
  }

  .add-container,
  .edit-container,
  .form-container,
  .button-container,
  .save-container,
  .confirmation-footer,
  .buttons-container {
    display: flex;

    .p-button {
      background-color: $color-blue;
      border-color: $color-blue;

      &:not(.no-hover):hover {
        background: #fff !important;
        color: $color-blue !important;

        border-color: $color-blue !important;
      }
    }

    .p-button-outlined {
      background-color: transparent;
      color: $color-blue !important;

      &:not(.no-hover):hover {
        color: #fff !important;
        background: $color-blue !important;
        border-width: 1px;
      }

      &.blue {
        color: $color-blue !important;
        border-color: $color-blue !important;
      }
    }
  }

  .table-container {

    table {
      tbody {
        tr {
          td {
            a {
              color: $color-blue;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .id-head {
        width: 50px;
      }

      .date-head {
        width: 140px;
      }

      .id-body {
        width: 50px;
      }

      .branch-head {
        width: 150px;
        text-align: center;
      }

      .integrations-head {
        width: 150px;
        text-align: center;
      }

      .users-head {
        width: 150px;
        text-align: center;
      }

      .activity-data {
        width: 5px;
      }

      .branch-data {
        a {
          color: $color-lilac;

          &:hover {
            text-decoration: none;
          }
        }

        div.branch {
          &:hover {

            background-color: rgba(104, 101, 255, 0.2);
          }
        }
      }

      .integrations-data {
        a {
          color: $color-orange;

          &:hover {
            text-decoration: none;
          }
        }

        div.integration {
          &:hover {
            background-color: rgba(253, 172, 77, 0.2);
          }
        }
      }

      .users-data {
        a {
          color: $color-blue-light;

          &:hover {
            text-decoration: none;
          }
        }

        div.user {
          &:hover {
            background-color: rgba(15, 175, 226, 0.2);
          }
        }
      }
    }
  }

  .paginator-container {
    .p-paginator {
      .p-paginator-pages {
        .p-paginator-page {
          &.p-highlight {
            background-color: $color-blue;
          }
        }
      }
    }
  }
}

.detail-container {
  margin-top: 20px;
}

.additional-container{

  margin: 20px 0;
  border-top: 1px solid #9C9C9C;
  .detail {

  }
}


.react-datepicker-wrapper {

}
.title-container {
  margin: 40px 0;
}
.confirmation-footer {
  display: flex;
}

