.bag {
  background: url("/images/icons/bag.svg");

  &_gradient {
    background-color: rgba(104, 101, 255, 0.2);
    width: 32px !important;
    height: 32px !important;
    border-radius: 10px;
    position: relative;

    &:after {
      position: absolute;
      left: 9px;
      top: 10px;
      content: '';
      width: 22px;
      height: 22px;
      background: transparent url("/images/icons/bag_g.svg") no-repeat;
    }
  }
}

.house {
  background: url("/images/icons/house.svg") 50% 50% no-repeat;

  &_gradient {
    background-color: rgba(104, 101, 255, 0.2);
    width: 32px !important;
    height: 32px !important;
    border-radius: 10px;
    position: relative;

    &:after {
      position: absolute;
      left: 5px;
      top: 10px;
      content: '';
      width: 22px;
      height: 22px;
      background: transparent url("/images/icons/house_g.svg") no-repeat;
    }
  }
}

.house2 {
  background: url("/images/icons/companies.svg") 50% 50% no-repeat;

  &_gradient {
    background-color: rgba(15, 175, 226, 0.2);
    width: 32px !important;
    height: 32px !important;
    border-radius: 10px;
    position: relative;

    &:after {
      position: absolute;
      left: 5px;
      top: 5px;
      content: '';
      width: 22px;
      height: 22px;
      background: transparent url("/images/icons/companies_g.svg") no-repeat;
    }
  }
}

.chain {
  background: url("/images/icons/chain.svg");

  &_gradient {
    background-color: rgba(253, 172, 77, 0.2);
    width: 32px !important;
    height: 32px !important;
    border-radius: 10px;
    position: relative;

    &:after {
      position: absolute;
      left: 8px;
      top: 8px;
      content: '';
      width: 22px;
      height: 22px;
      background: transparent url("/images/icons/chain_g.svg") no-repeat;
    }
  }
}

.avatar {

  &__black {
    width: 16px!important;
    height: 16px!important;
    background: url("/images/icons/user_black.svg");
  }

  &__green {
    background: url("/images/icons/user_green.svg");

    &_gradient {
      background-color: rgba(111, 207, 151, 0.2);
      width: 32px !important;
      height: 32px !important;
      border-radius: 10px;
      position: relative;

      &:after {
        position: absolute;
        left: 9px;
        top: 9px;
        content: '';
        width: 16px;
        height: 16px;
        background: transparent url("/images/icons/user_green.svg") no-repeat;
      }
    }
  }
  &__blue {
    background: url("/images/icons/user_blue.svg");

    &_gradient {
      background-color: rgba(15, 175, 226, 0.2);
      width: 32px !important;
      height: 32px !important;
      border-radius: 10px;
      position: relative;

      &:after {
        position: absolute;
        left: 9px;
        top: 9px;
        content: '';
        width: 16px;
        height: 16px;
        background: transparent url("/images/icons/user_blue_g.svg") no-repeat;
      }
    }
  }
}

.gear {
  background: url("/images/icons/gear.png");
  width: 32px!important;
  height: 32px!important;
}
.blank-link {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 3H21V9' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 14L21 3' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.bg-red {
  background-color: red;
}

.ico {
  display: block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}
