@import 'styles/vatiable';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/icons';

@import 'styles/primereact/form';
@import 'styles/primereact/button';

html, body, #root {
  font-family: $font-family !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  color: $color-text !important;
  height: 100%;
  font-style: normal;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

a {
  color: $color-text;
  text-decoration: none;
}

ul, ol {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }
}

h1 {
  font-family: $font-family2 !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  margin: 32px 0 !important;
}

h2 {
  font-family: $font-family2 !important;
  font-weight: 400;
  font-size: 24px;
}

label, .p-float-label {
  font-size: 12px;
  color: $color-gray-light;
  line-height: normal !important;
}

#root, .page-container {
  width: 100%;
}

.content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.content {
  width: 100%;
  max-width: 1650px;
}

.search-container {
  padding-top: 20px;
  display: flex;
  align-items: flex-end;

  .inputtext-container {
    .p-inputtext {
      width: 400px;
    }
  }

  .button-container {
    margin-left: 24px;
  }
}

.search-container {
  .dropdown-container {
    min-width: 250px;
  }
}

.breadcrumbs-container {
  padding: 40px 0 4px 0;
}

.switch {
  display: flex;
  align-items: center;

  &-activity {
    .p-inputswitch {
      &.p-inputswitch-checked, &.p-inputswitch-checked:not(.p-disabled):hover {
        .p-inputswitch-slider {
          background-color: $color-green;
        }
      }
    }

  }

  &__text {
    margin-left: 15px;
  }
}

.form-container {
  display: flex;
  align-items: flex-end;

  .p-field {
    //display: flex;
  }

  .edit-form {
    &__row {
      display: flex;
      flex-wrap: wrap;

      .field {
        &__account {
          padding-top: 48px;
        }
      }
    }

    &__col {
      width: 280px;
      margin-right: 24px;

      .report-button {
        &__wrapper {
          display: flex;
          width: 500px;
          justify-content: flex-start;

          .p-button {
            margin-left: 10px;
          }

          .react-datepicker-wrapper {
            width: auto;
          }
        }
      }

      &_flex {
        display: flex;
      }

      &_title {
        display: flex;
        width: auto;
        align-items: center;
      }

      &_large {
        width: 584px;
      }

      &_md {
        width: 240px;
        margin-right: 1px;
      }

      &_sm {
        width: 100px;
      }

    }
  }
}


.detail-container {

  .detail {
    .table-container {
      padding: 0 0 80px 0;

      .buttons-container {
        padding: 20px 0;
        margin: 0;
      }

      table {
        thead {
          tr {
            th {
              padding-right: 80px !important;
            }
          }
        }

        tbody {
          tr {
            td {
              padding-right: 80px;
            }
          }
        }

        width: auto;
      }
    }
  }
}

.buttons-container {
  //padding: 20px 0;
  margin: 0;

  &__position_top {
    padding: 0 0 20px 0 !important;
  }

  &__position_bottom {
    border-top: 1px solid $color-gray-light2;
    padding: 20px 0 0 0;
    margin-top: 60px !important;
  }
}

.title-container_min-mar-bottom {
  margin-bottom: 10px;
}

.p-dialog-header-icons {

}

.table-container {
  padding: 20px 0 40px 0;
  font-family: $font-family;

  .title-container {
    margin: 20px 0;
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      &:last-child {
        td {
          border-bottom: 1px solid $color-gray-light2;
        }
      }
    }

    th {
      text-align: left;
      border-top: 1px solid $color-gray-light2;
    }

    th {
      padding: 16px 5px !important;
    }

    th, td {
      border-top: 1px solid $color-gray-light2;
      padding: 10px 5px;

    }

    tr.branches-list {
      &:nth-child(even) {
        td {
          background-color: $color-light;
        }
      }

      td {
        border-top-width: 0;
        padding: 10px 5px;
      }
    }
  }
}

.form-container {
  display: flex;
  align-items: flex-end;
  padding-bottom: 80px;
}


.button-container {
  margin-right: 16px;
}

.paginator-container {
  padding-bottom: 40px;
}

.badge {
  border-radius: 50px;
  font-size: 12px;
  color: #FFF !important;
  padding: 3px 10px;
  height: 20px;
  position: relative;
  white-space: nowrap;

  &_active-true {
    background-color: $color-green;
  }

  &_active-false, &_linked-false {
    background-color: $color-gray-light;
  }

  &_linked-true {
    background-color: $color-lilac;
  }
}


//TODO кастомизацию ui в отдельный css файл
.p-field > label {
  margin: 0;
}

.p-disabled {
  opacity: 1;
  background-color: $color-light;

  .p-dropdown-trigger {
    display: none;
  }
}

.p-inputgroup {
  .p-component {
  }
}

.p-component {

  &.p-inputtext {
    &.p-inputtext-sm {
      height: 30px;
      font-size: 12px;
      margin-bottom: 0 !important;
    }
  }

  &.p-button {
    &.p-inputtext-sm {
      height: 30px;
      width: auto;
      line-height: 1px;
      font-size: 12px;
      padding: 0 7px;

      .p-button-icon {
        font-size: 10px;
      }
    }
  }

  &:disabled {
    opacity: 1;
    background-color: $color-light;
  }

  &.p-dropdown-panel {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-size: 14px;
    font-family: $font-family;
    color: $color-text;
  }

  &.p-inputtext {
    border: 1px solid $color-gray;
    color: $color-text;
    height: 41px;
    display: flex;
    align-items: center;
  }

  &.p-dropdown {
    height: 41px;
    border: 1px solid $color-gray;
    color: $color-text;

    .p-inputtext {
      color: $color-text;
      height: 41px;
      display: flex;
      align-items: center;

    }

    &:not(.p-disabled):hover {
      border-color: $color-gray;
    }
  }

  &.p-inputwrapper {
    width: 100%;
  }

  &.p-button {
    height: 41px;
    padding: 8px 20px;

    &.p-button-sm {
      height: auto !important;
      padding: 5px 10px;

      .p-button-label {
        font-size: 12px;
        line-height: 17px;
      }
    }

  }


}

.p-button {
  &.p-disabled {
    opacity: 0.5;
  }
}

.p-inputswitch {
  height: 24px;
  width: 42px;

  .p-inputswitch-slider {
    border-radius: 50px;

    &:before {
      top: 12px;
      width: 18px;
      height: 18px;
      border-radius: 50px;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider::before {
      transform: translateX(16px);
    }
  }
}


.p-paginator {
  .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
    border: 0 !important;
    color: $color-text;
    border-radius: 100px;
    margin: 0 3px;
    width: 40px;
    height: 40px;

    &:not(.p-disabled):not(.p-highlight):hover {
      background: $color-gray-light2;
      border-color: $color-gray-light2;
      color: $color-text;
    }
  }

  .p-paginator-pages {
    .p-paginator-page {
      border: 0 !important;
      color: $color-text;
      border-radius: 100px;
      margin: 0 3px;
      width: 40px;
      height: 40px;

      &:not(.p-highlight):hover {
        background: $color-gray-light2;
        border-color: $color-gray-light2;
        color: $color-text;
      }

      &.p-highlight {
        background-color: $color-blue;
      }
    }
  }
}

.pi-sort-amount-down, .pi-sort-amount-up-alt {
  font-size: 16px;
}

.btn-gen-password {
  align-self: center;
}

._text-muted {
  opacity: 0.6;
}

.pt {
  &-0 {
    padding-top: 0;
  }

  &-10 {
    padding-top: 10px;
  }
}

.pb {
  &-0 {
    padding-bottom: 0;
  }
}

.mb {
  &-20 {
    margin-bottom: 20px;
  }
}
