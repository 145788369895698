@import 'styles/main';

.profile {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-size: 12px;
  padding: 24px 34px 17px 24px;

  &__user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 28px;
    cursor: pointer;
  }

  &__ico {
    margin-right: 7px;
  }

  &__user-name {
    white-space: nowrap;
  }

  &__separator {
    display: none;
    height: 1px;
    background-color: $color-gray-light2;
    margin-left: -24px;
    margin-right: -34px;
  }

  ul {
    display: none;

    li {
      padding: 9px 34px 9px 0;
      color: $color-blue;
      cursor: pointer;

      &:first-child {
        padding-top: 15px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.active {
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    background-color: $color-white;

    ul, .profile__separator {
      display: block;
    }
  }

}

