.parsing-status {
  padding: 8px 11px;
  margin: 5px 0;
  width: auto;
  display: inline-block;
  text-transform: uppercase;
  border: 1px solid #ccc;

  &__created {
    border: 1px solid #ffa400;
    background-color: #feffd9;
    color: #ffa400
  }

  &__disconnected {
  }

  &__queued {
  }

  &__connected {
  }
}

.parser {
  &__status, &__dates {
    margin-top: 5px;
    font-size: 10px;
  }
}
