$color-blue: #2F80ED; //синий
$color-lilac: #6865FF;//сиреневый
$color-orange: #FDAC4D;//желтый
$color-blue-light: #0FAFE2;//голубой
$color-green-light: #6FCF97;//светло-зеленый
$color-red: #DA44CB;//красный
$color-text: #373947;//цвет текст
$color-gray: #6F6F6F;//серый
$color-gray-light: #9C9C9C;//светло-серый
$color-gray-light2: #DBDBDB;//светло-серый 2
$color-light: #F8F8F8;//светлый (заменыа белому)
$color-green: #32CC32;//зеленый
$color-blue-light2: #0FAFE2; //светлоголубой

$color-white: #FFFFFF;
$color-black: #000000;
$color-warning: #e74c3c;
$color-green-main: #008E9A


