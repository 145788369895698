@import "~react-datepicker/dist/react-datepicker.css";
@import 'styles/vatiable';
@import 'styles/fonts';
@import 'styles/colors';

.OKReviewCalendar {
  //background: red;
  .react-datepicker {
    &__day {
      &--keyboard-selected, &--in-range {
        border-radius: 0;
        background-color: #2F80ED;
        color: #fff;
      }

      &--in-range, &--in-selecting-range {
        background-color: #7bb1ee;
        border-radius: 0;
      }

      &--range-end, &--range-start {
        background-color: #2F80ED;
      }

      &:hover {
        border-radius: 0;
        background-color: #2F80ED;
        color: #fff;

      }
    }

    &__month-text {
      font-family: $font-family !important;
      padding: 5px 10px;
      color: $color-text;

      &--keyboard-selected {
        background-color: transparent;
      }

      &:hover {
        border-radius: 0;
        background-color: #2F80ED;
        color: #fff;

      }
    }

    &__month {
      //padding: 20px;
    }

    &__month--selected {
      border-radius: 0;
      background-color: #2F80ED;
      color: #fff;
    }
  }
}