@import 'styles/main';

.tag {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;

  .ico {
    width: 16px;
    height: 16px;
  }

  .text {
    display: inline-block;
    margin-left: 5px;
  }
}
