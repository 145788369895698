@import 'styles/main';

.integrations {
  &.modal {
    padding: 10px 50px 40px 50px;
    position: relative;

    .header-container {
      h1 {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .btn-close-modal {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    .table-container {
      padding-top: 40px;
    }
  }

  .header-container {
    h1 {
      span {
        color: $color-orange;
      }
    }
  }

  .add-container,
  .edit-container,
  .form-container,
  .button-container,
  .save-container,
  .buttons-container {
    display: flex;

    .p-button {
      background-color: $color-orange;
      border-color: $color-orange;

      &:hover {
        color: $color-orange !important;
        background: #fff !important;
        border-color: $color-orange !important;
      }
    }

    .p-button-outlined {
      background-color: transparent;
      color: $color-orange !important;

      &:hover {
        color: #fff !important;
        background: $color-orange !important;
        border-width: 1px;
      }

      &.color-lilac {
        color: $color-lilac !important;
        border-color: $color-lilac !important;
        &:hover {
          color: #fff !important;
          background: $color-lilac !important;
          border-width: 1px;
        }
      }
    }
  }

  .search-container {
    padding-top: 20px;
    display: flex;

    .dropdown-container {
      width: 250px;
    }

    .inputtext-container {
      margin-left: 24px;

      .p-inputtext {
        width: 400px;
      }
    }

    .button-container {
      margin-left: 24px;
    }
  }

  .branches-list {
    a {
      color: $color-lilac !important;
    }
  }

  .table-container {

    table {
      tbody {
        tr {
          td {
            padding: 10px 8px;

            a {
              color: $color-orange;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .id-head {
        width: 50px;
      }

      .activity-data {
        width: 5px;
      }
    }

    .btn-open-modal {
      width: 90px;
      padding: 6px 9px;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      border: 1px solid $color-orange;
      border-radius: 4px;

      &__ico {
        width: 20px;
        height: 20px;
      }
    }

    .btn-show-branches {
      width: 85px;
      padding: 2px 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      cursor: pointer;

      &__ico {
        width: 20px;
        height: 20px;
      }

      &__text {
        padding: 0 8px;
      }

      &_closed {
        .pi-chevron-up {
          display: none;
        }

        .pi-chevron-down {
          display: block;
        }
      }

      &_open {
        background-color: rgba(104, 101, 255, 0.2);

        .pi-chevron-up {
          display: block;
        }

        .pi-chevron-down {
          display: none;
        }
      }

    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked, &.p-inputswitch-checked:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background-color: $color-orange;
      }
    }
  }


  .paginator-container {
    .p-paginator {
      .p-paginator-pages {
        .p-paginator-page {
          &.p-highlight {
            background-color: $color-orange;
          }
        }
      }
    }
  }
}

.branches {
  &.table-container {
    table {
      .badge {
        &_linked-true {
          background-color: $color-lilac !important;
        }
      }

      .p-inputswitch {
        &.p-inputswitch-checked, &.p-inputswitch-checked:not(.p-disabled):hover {
          .p-inputswitch-slider {
            background-color: $color-lilac;
          }
        }
      }

      tbody {
        tr {
          td {
            a {
              color: $color-lilac !important;
            }
          }
        }
      }
    }
  }
}

.switch {
  &__text {
    &.disabled {
      opacity: .5;
    }
  }
  &__wrapper {
    align-self: end;
    margin-bottom: 27px;
  }
}
