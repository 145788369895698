@import "styles/main";

.login {
  height: 100%;

  .content-container {
    height: 100%;
    padding-top: 67px;
    margin-top: -67px;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.loginForm-container {
  width: 800px;
  height: 420px;
}

.loginForm {
  background: #FFFFFF;
  box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 56px;
  display: flex;
  flex-direction: row;

  .image {
    width: 50%;
  }

  .form {
    padding-top: 15px;
    padding-left: 80px;
    width: 50%;

    .button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 26px;

      .p-button {
        background-color: $color-blue;
        border-color: $color-blue;

        &:hover {
          background: #fff !important;
          color: $color-blue !important;

          border-color: $color-blue !important;
        }
      }

      a {
        color: $color-blue;
        display: inline-block;
      }
    }

    .checkbox-container {
      margin-top: -4px;
    }
  }
}
