@import 'styles/main';

.overflow-link {
    position: relative;
    display: block;
    height: 28px;
    width: 290px;
    margin: 4px -16px;

    a {
        display: block;
        position: relative;
        box-sizing: border-box;
        padding: 8px 16px;
        max-width: 280px;
        color: $color-text !important;
        border-radius: 4px;
        text-decoration: none !important;
        background: transparent;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:after {
            content: '';
            display: none;
            position: absolute;
            right: 9px;
            top: 50%;
            margin-top: -13px;
            width: 22px;
            height: 22px;
            background: transparent url("/images/icons/external-link.svg") no-repeat;
        }

        &:hover {
            position: absolute;
            padding-right: 35px;
            background: $color-light;
            overflow: visible;
            text-overflow: clip;
            white-space: normal;
            z-index: 1;

            &:after {
                display: block;
            }
        }
    }
}

