@import 'styles/main';

.branches {
  .header-container {
    h1 {
      span {
        color: $color-lilac;
      }
    }
  }

  .search-container {
    .dropdown-container {
      width: 250px;
    }
  }

  .add-container,
  .edit-container,
  .form-container,
  .button-container,
  .save-container,
  .buttons-container {
    display: flex;

    .p-button {
      background-color: $color-lilac;
      border-color: $color-lilac;

      &:hover {
        color: $color-lilac !important;
        background: #fff !important;
        border-color: $color-lilac !important;
      }
    }

    .p-button-outlined {
      background-color: transparent;
      color: $color-lilac !important;

      &:hover {
        color: #fff !important;
        background: $color-lilac !important;
        border-width: 1px;
      }

      &.color-blue-light {
        color: $color-blue-light !important;
        border-color: $color-blue-light !important;
      }
    }
  }

  .table-container {

    table {

      tbody {
        tr {

          td {
            padding: 15px 8px;

            a {
              color: $color-lilac !important;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .id-head {
        width: 50px;
      }

      .activity-data {
        width: 5px;
      }
    }
  }


  .paginator-container {
    .p-paginator {
      .p-paginator-pages {
        .p-paginator-page {
          &.p-highlight {
            background-color: $color-lilac;
          }
        }
      }
    }
  }
}

.integrations {
  &.table-container {
    table {
      .badge {
        &_linked-true {
          background-color: $color-orange !important;
        }
      }

      .p-inputswitch {
        &.p-inputswitch-checked, &.p-inputswitch-checked:not(.p-disabled):hover {
          .p-inputswitch-slider {
            background-color: $color-orange;
          }
        }
      }

      tbody {
        tr {
          td {
            a {
              color: $color-orange !important;
            }
          }
        }
      }
    }
  }
}

.users {
  &.table-container {
    table {
      .badge {
        &_linked-true {
          background-color: $color-blue-light !important;
        }
      }

      .p-inputswitch {
        &.p-inputswitch-checked, &.p-inputswitch-checked:not(.p-disabled):hover {
          .p-inputswitch-slider {
            background-color: $color-blue-light;
          }
        }
      }

      tbody {
        tr {
          td {
            a {
              color: $color-blue-light !important;
            }
          }
        }
      }
    }
  }
}
